*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Libre Baskerville, sans-serif;
}
body, html{
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
a{
    text-decoration: none;
    color: white;
}
