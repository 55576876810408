
.nav_btn a {
    display: block;
    padding: 15px;
}
.nav_btn a:hover {
    border-bottom: 2px solid #6C66B9;
}

.selected{
    border-bottom: 2px solid #6C66B9;
}
.myLogo{
    width: 105px;
}

@media(max-width:500px){
    .mobile_nav{
        width: 100%;
    }
    .nav_btn a{
        background: #0E0824;
    }
}
